<template>
  <div class="wrap-edit bpxm">
    <el-card>
        <float-tit-btn class="wr">
        <div class="edit-header">
          <span>报批项目</span>
          <el-button type="primary" v-show="!isDisabled" @click="save">保存</el-button>
        </div>
        </float-tit-btn>
      <el-form
        ref="baseform"
        :model="form"
        label-width="130px"
        label-position="left"
      >
        <el-row :gutter="24">
          <el-col :span="12">
            <el-form-item label="项目类型：">
              <el-input v-model="form.XM_LX" :disabled="isDisabled"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="项目名称：">
              <el-input v-model="form.XM_MC" :disabled="isDisabled"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="项目编号：">
              <el-input v-model="form.XM_BH" :disabled="isDisabled"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="申请单位：">
              <el-input v-model="form.SQ_DW" :disabled="isDisabled"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="行政区代码：">
              <el-input v-model="form.XS_DM" :disabled="isDisabled"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="指标类型：">
              <el-input v-model="form.ZB_LX" :disabled="isDisabled"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="指标年度：">
              <el-input v-model="form.ZB_ND" :disabled="isDisabled"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="指标级别：">
              <el-input v-model="form.ZB_JB" :disabled="isDisabled"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="申请日期：">
              <el-date-picker  style="flex: 1; width: 100%" v-model="form.SQ_RQ" :disabled="isDisabled" format="YYYY-MM-DD" value-format="YYYY-MM-DD">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="申请意见：">
              <el-input v-model="form.SQ_YJ" :disabled="isDisabled"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="准批机关：">
              <el-input v-model="form.ZZPZ_JG" :disabled="isDisabled"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="市批准日期：">
              <el-input v-model="form.S_PZ_RQ" :disabled="isDisabled"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="批准文号：">
              <el-input v-model="form.PF_WH" :disabled="isDisabled"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="批准日期：">
              <el-date-picker style="flex: 1; width: 100%" v-model="form.PZ_RQ" format="YYYY-MM-DD"
                                value-format="YYYY-MM-DD" :disabled="isDisabled">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="部批准文号：">
              <el-input v-model="form.B_PZ_WH" :disabled="isDisabled"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="部批准日期：">
              <el-date-picker style="flex: 1; width: 100%" v-model="form.B_PZ_RQ" format="YYYY-MM-DD"
                                value-format="YYYY-MM-DD" :disabled="isDisabled">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="档案编号：">
              <el-input v-model="form.DA_BH" :disabled="isDisabled"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="备注:">
              <el-input v-model="form.BZ" type="textarea" :rows="2" :disabled="isDisabled"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-card>
    <br />
    <el-card v-show="isTableVisible">
      <template #header>
        <div class="edit-header">
          <span>农转用项目</span>
          <el-button type="success" v-show="!isDisabled" @click="createNZY">新增农转用</el-button>
        </div>
      </template>
      <PublicTable ref="publicTable">
        <template #td_XM_BH="scope">
          <span class="tes" @click="opentask(scope.$index, scope.row)">{{
            scope.row.XM_BH
          }}</span>
        </template>
        <template #td_XM_GUID="scope">
          <span class="tes" @click="toMap(scope.row.XM_GUID)">一张图</span>
        </template>
      </PublicTable>
    </el-card>
    <el-dialog
      v-model="dialogFileVisible"
      title="一张图"
      :close-on-click-modal="false"
    >
      <embed
        style="width: 100%; height: 65vh"
        frameborder="0"
        :src="mapUrl + '#toolbar=0'"
      />
    </el-dialog>
  </div>
</template>

<script>
import { ElMessage } from "element-plus";
import PublicTable from "@/components/table/PublicTable.vue";
import FloatTitBtn from '../../components/FloatTitBtn.vue';
export default {
  name:'bpxm',
  components: {
    PublicTable,
    FloatTitBtn,
  },
  data() {
    return {
      isTableVisible: false,
      form: {
        XM_GUID: "",
        BJID: "",
        XM_LX: "",
        XM_MC: "",
        XM_BH: "",
        SQ_DW: "",
        XS_DM: "",
        ZB_LX: "",
        ZB_ND: "",
        ZB_JB: "",
        SQ_RQ: new Date(),
        ZZPZ_JG: "",
        S_PZ_RQ: "",
        PF_WH: "",
        PZ_RQ: new Date(),
        B_PZ_WH: "",
        B_PZ_RQ: new Date(),
        DA_BH: "",
        BZ: "",
      },
      isDisabled: true,
      dialogFileVisible:false,
      mapUrl:"",
    };
  },
  methods: {
    toMap(guid){
      let me = this;
      this.$ajax.get(this.$appConfig.apiUrls.ywxtApi
        + '/api/YW/Getyzturlguidtype?bjid='
        + this.$route.query.instanceId + '&fid='
        + this.$route.query.fid)
        .then((r) => {
          let res = r.data.Data
          me.dialogFileVisible=true
          if (r.data.IsSuccess) {
            me.mapUrl =me.$appConfig.mapUrl2+ '?fid=' + me.$route.query.fid + '&bjid=' + me.$route.query.instanceId + '&guid=' + guid + '&type=' + res.type + '&p=1';
          } else {
            me.mapUrl =me.$appConfig.mapUrl2+ '?fid=' + me.$route.query.fid + '&bjid=' + me.$route.query.instanceId + '&guid=' + guid + '&type=' + 'JSYDSP' + '&p=1';
          }
        })
    },
    async fetch() {
      try {
        await this.$ajax
          .get(
            this.$appConfig.apiUrls.ywxtApi +
              "/api/YW/getnzybpxm?BJID=" +
              this.form.BJID
          )
          .then((response) => {
            if (response.data.IsSuccess) {
              this.form = response.data.Data;
            } else {
              throw response.data.ErrorMessage;
            }
            // console.log('报批项目获取：',response,this.form);
            if (this.form.JBR === this.$router.user.profile.sub && this.$route.query.taskId) {
              this.isDisabled = false;
            }
          });
        if (this.form.XM_GUID) {
          this.isTableVisible = true;
          const condition = {
            Key: "BP_GUID",
            Value: this.form.XM_GUID,
            IsCondition: true,
            Operator: "=",
            LogicalOperator: "and",
          };
          this.$refs.publicTable.getLoadData("nzynzyxm", [condition]);
        }
      } catch (error) {
        console.log(error);
      }
    },
    async save() {
      try {
        await this.$ajax
          .post(
            this.$appConfig.apiUrls.ywxtApi + "/api/YW/AddorUpdatnzybpxm",
            this.form
          )
          .then(function (response) {
            if (response.data.IsSuccess) {
              ElMessage({
                type: "success",
                message: "保存成功!",
              });
            } else {
              ElMessage({
                type: "info",
                message: "保存失败!",
              });
              console.log(response.data.ErrorMessage);
            }
          });
        if (!this.form.XM_GUID) {
          await this.fetch();
        }
      } catch (error) {
        console.log(error);
      }
    },
    createNZY() {
      const query = {
        bpid: this.form.XM_GUID,
        instanceId: this.form.BJID,
      };
      this.$router.push({ path: "/nzyxm", query });
    },
    opentask(index,row) {
      const query = {
        id: row.XM_GUID,
      };
      this.$router.push({ path: "/nzyxm", query });
    },
  },
  mounted() {
    this.form.BJID = this.$route.query.instanceId ?? "";
    this.fetch();
  },
};
</script>

<style lang="scss" scoped>
.wr{
  padding-right: 20px;
  padding-left: 20px;
  margin-left: -20px;
}
.edit-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.el-form{
  margin-top: 40px;
}
// .el-form-item {
//   margin: 11px 0;
// }
</style>

<style lang="scss">
.bpxm {
  .el-form-item__content {
    display: flex;
    align-items: center;
  }

  .el-card__header {
    padding: 10px 20px;
  }

  .table-footer {
    border: none;
  }

  .el-table__body-wrapper {
    height: 100% !important;
  }
}
</style>
